<template>
    <div>
        <HeaderNav v-if="!$store.state.isNavBarOff" :auth="$store.state.isAuth"></HeaderNav>
        <div class="test">
            <SideBarDesktop v-if="$store.state.isNavBarOff" />

            <div class="wrapper-lc">
                <div class="container">
                    <div class="notification-title">Уведомления</div>
                    <NotificationList />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderNav from "@/components/HeaderNav.vue";
import NotificationList from "@/components/NotificationList.vue";
import SideBarDesktop from "@/components/SideBarDesktop.vue";

export default {
    components: { HeaderNav, NotificationList, SideBarDesktop },
};
</script>

<style lang="scss" scoped>
.notification-title {
    font-family: "PT Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.005em;

    color: #1a1a1a;
    margin-bottom: 32px;
}

@media screen and (max-width: 4000px) and (min-width: 769px) {
    .container {
        padding: 60px;
    }

    .notification-title {
        font: 700 40px "PT Sans";
    }
}
</style>
