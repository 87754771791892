<template>
    <div class="wrapper-notification">
        <div v-if="!this.$store.state.notificationList.length">Уведомлений нет</div>

        <div
            class="notification-container"
            v-for="item in this.$store.state.notificationList"
            :key="item.id"
            @click="navigateCheck(item.id, item.entity_id)"
        >
            <div class="notification-content">
                <div class="notification-content__title">
                    <div>
                        <div
                            class="notification-content-title__new"
                            :class="{ visibleNewNotification: item.status }"
                        />
                        <p>
                            {{ item.entity_type }}
                        </p>
                    </div>

                    <div class="time">{{ convertTime(item.created_at) }}</div>
                </div>
                <div class="notification-content__text">
                    {{ item.notification_data }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";

export default {
    methods: {
        ...mapActions(["changeStatusNotification"]),

        convertTime(time) {
            return moment(time).utc().local().format("DD.MM.YYYY HH:mm");
        },

        navigateCheck(id, checkId) {
            this.changeStatusNotification(id);
            this.$router.push({ name: "LcSingle", params: { id: checkId } }).catch(() => {});
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper-notification {
    width: 100%;
}

.notification-container {
    padding: 12px 16px;
    margin-bottom: 12px;

    display: flex;
    flex-direction: column;

    background: #f1f1f1;

    font: 400 10px "Inter";
    line-height: 20px;
    border-radius: 10px;
    cursor: pointer;
}
.time {
    font: 400 12px "Inter";
    line-height: 20px;
    border-radius: 10px;
}

.notification-content {
    position: relative;

    display: flex;
    flex-direction: column;
}
.notification-content__title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font: 700 20px "Inter";
    line-height: 20px; /* 111.111% */
    letter-spacing: -0.27px;
}

.notification-content__title p {
    padding-left: 12px;
}

.notification-content-title__new {
    position: absolute;
    top: 7px;
    left: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #fa73a4;
    margin-right: 12px;
}

.visibleNewNotification {
    display: none;
}

.notification-content__text {
    width: 100%;
    text-align: start;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;

    letter-spacing: -0.015em;

    color: #1a1a1a;
    margin-top: 16px;
}
</style>
