<template>
    <div class="plug-wrapper">
        <!-- <div class="plug-header">
            <img class="plug-header__logo" src="@/assets/logo.svg" alt="logo" />
            <div class="plug-header-nuvbar">
                <span class="plug-header-nuvbar__item">Для агентств</span>
                <span class="plug-header-nuvbar__item">О решении</span>
                <span class="plug-header-nuvbar__item">Контакты</span>
            </div>
            <button class="plug-header__btn">получить предложение</button>
        </div> -->
        <div class="plug-body">
            <div class="plug-body-left">
                <h1>
                    Акция Matti Розыгрыш призов каждую неделю
                    <!-- приложение доступно только на&nbsp;смартфоне -->
                </h1>
                <span>
                    Чтобы воспользоваться сервисом, пожалуйста, откройте эту страницу <br />
                    с мобильного устройства, скопировав ссылку:
                </span>
                <div class="copy-ling-block">
                    <input
                        ref="input"
                        value="check.mattigift.ru"
                        readonly
                        v-on:focus="$event.target.select()"
                    />

                    <img
                        src="@/assets/copy_icon.svg"
                        alt="copy"
                        class="copy-ling-block__img"
                        @click="copy"
                    />
                </div>
            </div>
            <div class="plug-body-right">
                <div class="plug-body-right__block">
                    Или отсканируйте QR-код на&nbsp;мобильном устройстве
                </div>
                <div class="plug-body-right__qr">
                    <img src="@/assets/qr-logo.svg" alt="qr" />
                    <div class="plug-body-right__qr__elem-top-right"></div>
                    <div class="plug-body-right__qr__elem-top-left"></div>
                    <div class="plug-body-right__qr__elem-bottom-left"></div>
                    <div class="plug-body-right__qr__elem-bottom-right"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isMobile:
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
                    navigator.userAgent
                ),
            windowWidth: window.innerWidth,
        };
    },
    methods: {
        onResize() {
            this.windowWidth = window.innerWidth;
            // this.redirect();
        },
        redirect() {
            if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
                    navigator.userAgent
                )
            ) {
                this.$router.push({ name: "Start" }).catch(() => {});
            }
        },
        async copy() {
            this.$refs.input.focus();
            document.execCommand("copy");
            await this.$nextTick();
            this.$refs.input.blur();
        },
    },
    watch: {},
    mounted() {
        this.$nextTick(() => {
            window.addEventListener("resize", this.onResize);
        });
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.onResize);
    },
};
</script>

<style lang="scss" scoped>
.plug-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    // max-width: 100%;
    min-width: 1440px;
    overflow-y: auto;
    min-height: 100vh;
    padding: 60px 40px 60px 40px;
}

@media (max-width: 1360px) {
    .plug-body {
        align-items: start;
    }
}

.plug-header {
    position: relative;
    width: 100%;
    min-width: 720px;
    height: 78px;
    margin-bottom: 44px;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background: #ffffff;
    border-radius: 16px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #1f1f30;
}
.plug-header__logo {
    width: 58px;
    height: 58px;
    margin-right: 52px;
}
.plug-header-nuvbar {
    display: flex;
    align-items: center;
    min-width: 220px;
    width: 50%;
}
.plug-header-nuvbar__item {
    margin-right: 40px;
    cursor: pointer;
}
.plug-header__btn {
    position: absolute;
    right: 20px;
    width: 240px;
    height: 38px;
    background: #1f1f30;
    border: 1px solid #1f1f30;
    border-radius: 200px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;

    color: #ffffff;
}
.plug-body {
    position: relative;
    width: 100%;
    // margin-top: 30px; // УДАЛИТЬ КАК ПОЯВИТСЯ ШАПКА
    min-width: 1360px;
    height: 711px;
    background: #ffffff;
    padding: 57px 35px 57px 93px;
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.plug-body-left {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    width: 659px;
    height: 100%;
    // padding-top: 105px;
    margin-right: 10px;
}
.plug-body h1 {
    font-family: "Dewi_ExtraBold";
    font-size: 62px;
    line-height: 110%;
    width: 659px;
    margin-bottom: 40px;
    text-align: start;

    color: #1f1f30;
}

.plug-body span {
    font-family: "Dewi_Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 31px;
    width: 630px;
    color: #1f1f30;
    margin-bottom: 30px;
    text-align: start;
}
.copy-ling-block {
    height: 78px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    font-family: "Dewi_Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    color: #1f1f30;
    padding: 16px 40px;
    width: 100%;
    background: #ffffff;
    border: 2px solid #cccccc;
    border-radius: 10px;
    // text-align: start;
}
.copy-ling-block input {
    display: flex;
    align-items: start;
    width: 100%;
    font-size: 38px;
    line-height: normal;
    height: 46px !important;
    vertical-align: middle;
    outline: none;
    border: none;
}
.copy-ling-block__img {
    position: relative;
    // width: 50px;
    // height: 50px;
    // right: 30px;
    // top: 15px;
    cursor: pointer;
}
.plug-body-right {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
}
.plug-body-right__block {
    position: relative;
    max-width: 543px;
    width: 543px;
    height: 178px;
    background-image: url("@/assets/message.png");
    background-repeat: no-repeat;
    font-family: "Dewi_Regular";
    padding: 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 31px;
    text-align: center;
    padding-bottom: 80px;
    color: #ffffff;
}
.plug-body-right__qr {
    position: relative;
    // text-align: start;
    width: 543px;
    padding: 20px;
    height: 100%;
    // margin-top: 10px;
}
.plug-body-right__qr img {
    position: relative;
    // text-align: start;
    width: 392px;
    // height: 100%;
    // margin-top: 10px;
}
.plug-body-right__qr__elem-top-right {
    position: absolute;
    background-image: url("@/assets/Vector_plugpage.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 60px;
    height: 60px;
    top: 5px;
    right: 59px;
}
.plug-body-right__qr__elem-top-left {
    position: absolute;
    position: absolute;
    background-image: url("@/assets/Vector_plugpage.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 60px;
    height: 60px;
    transform: rotate(-90deg);
    top: 5px;
    left: 59px;
}
.plug-body-right__qr__elem-bottom-left {
    position: absolute;
    position: absolute;
    background-image: url("@/assets/Vector_plugpage.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 60px;
    height: 60px;
    transform: rotate(180deg);
    bottom: 10px;
    left: 59px;
}
.plug-body-right__qr__elem-bottom-right {
    position: absolute;
    position: absolute;
    background-image: url("@/assets/Vector_plugpage.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 60px;
    height: 60px;
    transform: rotate(90deg);
    bottom: 10px;
    right: 59px;
}
</style>
