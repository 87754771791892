<template>
    <div></div>
</template>

<script>
import jwt_decode from "jwt-decode";
import axios from "axios";

export default {
    async mounted() {
        if (this.$route.query.token) {
            try {
                if (jwt_decode(this.$route.query.token)) {
                    const jwtData = jwt_decode(this.$route.query.token);

                    this.$cookies.set("cng_token", this.$route.query.token);

                    await axios.post(`${process.env.VUE_APP_MYHOST}/email-verify`, {
                        id: jwtData.id,
                    });

                    this.$router.push({ name: "lc" }).catch(() => {});
                    window.location.reload();
                }
            } catch (error) {
                console.error(error.message);
            }
        }
    },
};
</script>
